class TrackSelectorData {

  setId = (id) => {
    this.id = id;
    return this;
  }

  setCity = (city) => {
    this.city = city;
    return this;
  }

  setName = (name) => {
    this.name = name;
    return this;
  }

  setPluginFactoryMethod = (pluginFactoryMethod) => {
    this.pluginFactoryMethod = pluginFactoryMethod;
    return this;
  }

  setWebsite = (website) => {
    this.website = website;
    return this;
  }

  setAddress = (address) => {
    this.address = address;
    return this;
  }

  setGps = (gps, gpsLink) => {
    this.gps = gps;
    this.gpsLink = gpsLink;
    return this;
  }

  setMapLink = (mapLink) => {
    this.mapLink = mapLink;
    return this;
  }
};

export default TrackSelectorData;