class TrackData {

  constructor(frameTimestamp, id, name, racers) {
    this.frameTimestamp = frameTimestamp;
    this.id = id;
    this.name = name;
    this.racers = (racers) ? racers : [];
  }

  getRacer = (racerId) => {
    return this.racers.find(racer => String(racer.id) === String(racerId));
  }
}

export default TrackData;