import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import AtIcon from '@material-ui/icons/AlternateEmailOutlined';


import './track-selector.css'
import TrackSelectorRow from './track-selector-row';
import { AnalyticsPageView } from '../utils/react-ga/react-ga-utils';


class TrackSelectorComponent extends Component {


  componentDidMount() {
    this.props.loadTracksList();
    AnalyticsPageView();
  }


  render() {
    const selectedTrack = this.props.selectedTrack.value;

    return (
      <div className="track-selector paper-list-container">
        <Grid container spacing={8}>
          <Grid item xs={2}>
            <Link className='app-link' component={RouterLink} to={'/'}>
              <Paper className='paper paper-list-header'><KeyboardArrowLeft /></Paper>
            </Link>
          </Grid>
          <Grid item xs={8}><Paper className='paper paper-list-header'>Wybierz tor</Paper></Grid>
          <Grid item xs={2}>
            <Paper className='paper paper-list-header'>&nbsp;</Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className='paper paper-list'>
              Brak toru? Napisz do mnie!
              lukasz.kotynski.dev<AtIcon className='magicIcon' />gmail.com&nbsp;
              <Link className='app-link' component={RouterLink} to={'/new-track-faq'}>
                Czego potrzebuję?
              </Link>
            </Paper>
          </Grid>
          {this.props.tracksList.map(track => <TrackSelectorRow key={track.id} track={track} selectedTrack={selectedTrack} />)}
        </Grid>
      </div>
    );
  }
}

export default TrackSelectorComponent;