import TracksService from "./tracks-service";
import AsynchronousItem from "../../utils/asynchronous-item";

export const TRACKS_LOADS_TRACKS_TO_SELECT = 'TRACKS_LOADS_TRACKS_TO_SELECT'
export const TRACKS_MARK_AS_LOADING = 'TRACKS_MARK_AS_LOADING'
export const TRACKS_LOADS_TRACK_DATA = 'TRACKS_LOADS_TRACK_DATA'
export const TRACKS_CLEAR_RESULTS = 'TRACKS_CLEAR_RESULTS'

export function loadTracksList() {
  return {
    type: TRACKS_LOADS_TRACKS_TO_SELECT,
    tracks: TracksService.getTracks()
  };
}

export function markTrackDataAsLoading() {
  return {
    type: TRACKS_MARK_AS_LOADING,
    track: new AsynchronousItem(AsynchronousItem.STATE_LOADING)
  };
}

export function loadTrackData(track) {
  return {
    type: TRACKS_LOADS_TRACK_DATA,
    track: new AsynchronousItem(AsynchronousItem.STATE_OK, track)
  };
}

export function clearResults() {
  return {
    type: TRACKS_CLEAR_RESULTS,
  };
}