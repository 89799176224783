import TrackSelectorData from "./tracks/track-data/track-selector-data";
import KartingManagerComPlugin from "./reader/plugins/karting-manager-com-plugin";
import TestTrackPlugin from "./reader/plugins/test-track-plugin";
import KartingManagerComTestPlugin from "./reader/plugins/karting-manager-com-test-plugin";

export const TRACKS = [
  new TrackSelectorData()
    .setId('test-track')
    .setCity('Test')
    .setName('Tor Testowy')
    .setPluginFactoryMethod(() => new TestTrackPlugin())
    .setWebsite('laptimer.kotu.dev')
    .setAddress('Adres testowy')
    .setGps('51.877468, 19.5314108', 'https://goo.gl/maps/HW9BgbGoyEa79XZeA')
    .setMapLink('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19282.522762905446!2d19.51422318342859!3d51.8774869641284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47196cc920d2e4db%3A0x8dfd6970e4bdec33!2sTOR+%C5%81%C3%93D%C5%B9+szkolenia+akademia+bezpiecznej+jazdy!5e0!3m2!1spl!2spl!4v1558174227435!5m2!1spl!2spl'),

  // new TrackSelectorData()
  //   .setId('test-karting-manager')
  //   .setCity('Test')
  //   .setName('Karting Manager Com')
  //   .setPluginFactoryMethod(() => new KartingManagerComTestPlugin('tor-lodz'))
  //   .setWebsite('https://tor-lodz.pl')
  //   .setAddress('Kiełmina 78, 95-010 Stryków')
  //   .setGps('51.877468, 19.5314108', 'https://goo.gl/maps/HW9BgbGoyEa79XZeA')
  //   .setMapLink('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19282.522762905446!2d19.51422318342859!3d51.8774869641284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47196cc920d2e4db%3A0x8dfd6970e4bdec33!2sTOR+%C5%81%C3%93D%C5%B9+szkolenia+akademia+bezpiecznej+jazdy!5e0!3m2!1spl!2spl!4v1558174227435!5m2!1spl!2spl'),

  new TrackSelectorData()
    .setId('tor-lodz')
    .setCity('Łódź')
    .setName('Tor Łódź')
    .setPluginFactoryMethod(() => new KartingManagerComPlugin('tor-lodz'))
    .setWebsite('https://tor-lodz.pl')
    .setAddress('Kiełmina 78, 95-010 Stryków')
    .setGps('51.877468, 19.5314108', 'https://goo.gl/maps/HW9BgbGoyEa79XZeA')
    .setMapLink('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19282.522762905446!2d19.51422318342859!3d51.8774869641284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47196cc920d2e4db%3A0x8dfd6970e4bdec33!2sTOR+%C5%81%C3%93D%C5%B9+szkolenia+akademia+bezpiecznej+jazdy!5e0!3m2!1spl!2spl!4v1558174227435!5m2!1spl!2spl'),

  new TrackSelectorData()
    .setId('silesiaring')
    .setCity('Kamień Śląski')
    .setName('Silesia Ring')
    .setPluginFactoryMethod(() => new KartingManagerComPlugin('silesiaring'))
    .setWebsite('http://silesiaring.pl')
    .setAddress('ul. Lotnicza 5-7, 47-325 Kamień Śląski')
    .setGps('50.528171, 18.096310', 'https://goo.gl/maps/nTZYCRU4PKBeHSHs9')
    .setMapLink('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38030.422048410706!2d18.065947387240886!3d50.52509735584217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4711013b25b23e25%3A0x3d667a3ea61eff65!2sSilesia+Ring!5e0!3m2!1spl!2spl!4v1558175238837!5m2!1spl!2spl'),
];
