import { connect } from 'react-redux'


import TrackSelectorComponent from './track-selector.component';
import { loadTracksList } from '../tracks/track-data/tracks.actions';


const mapStateToProps = state => {
  return ({
    selectedTrack: state.tracks.selectedTrack,
    tracksList: state.tracks.tracksList,
  })
};

const mapDispatchToProps = dispatch => ({
  loadTracksList: () => dispatch(loadTracksList())
});

const TrackSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackSelectorComponent);

export default TrackSelectorContainer;