import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class WarningDialog extends React.Component {
  handleClose = (result) => {
    this.props.onClose(result);
  };

  render() {
    return (
      <Dialog
        open={this.props.openFlag}
        onClose={() => this.handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose(false)} color="primary">
            Anuluj
        </Button>
          <Button onClick={() => this.handleClose(true)} color="primary" autoFocus>
            OK
        </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default WarningDialog;