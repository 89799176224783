import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteForever from '@material-ui/icons/DeleteForever'


import TracksService from '../tracks/track-data/tracks-service';
import AsynchronousItem from '../utils/asynchronous-item';
import WarningDialog from '../utils/warning-dialog/warning-dialog';
import { AnalyticsPageView, AnalyticsEvent } from '../utils/react-ga/react-ga-utils';


const ACTION_CLEAR_RESULTS = 'ACTION_CLEAR_RESULTS';


class RiderSelectorComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clearResultsWarningOpen: false
    };
  }

  setClearResultsWarningOpen = (value) => {
    this.setState({
      clearResultsWarningOpen: value
    });
  }

  componentDidMount() {
    AnalyticsPageView();
    this.props.loadPlugin(this.props.match.params.id);
  }

  render() {
    const track = TracksService.getTrack(this.props.match.params.id);

    return (
      <div className="rider-chooser paper-list-container">
        <Grid container spacing={8}>
          <Grid item xs={2}>
            <Link className='app-link' component={RouterLink} to={'/track'}>
              <Paper className='paper paper-list-header'><KeyboardArrowLeft /></Paper>
            </Link>
          </Grid>
          <Grid item xs={8}><Paper className='paper paper-list-header'>{track.name}</Paper></Grid>
          <Grid item xs={2}>
            <Link className='app-link' onClick={this.openClearResultsWarning}>
              <Paper className='paper paper-list-header'>
                <DeleteForever />
              </Paper>
            </Link>
          </Grid>
          {this.renderContent(track)}
        </Grid>
        <WarningDialog
          openFlag={this.state.clearResultsWarningOpen}
          onClose={(result) => this.closeClearResultsWarning(result)}
          title="Czy usunąć wyniki?"
          body="Usunięcie wyników jest trwałe i nieodwracalne. Czy na pewno chcesz to zrobić?"
        />
      </div>
    );
  }

  renderContent(track) {
    if (this.props.selectedTrack.state === AsynchronousItem.STATE_LOADING) {
      return this.renderLoadingIndicator();
    } else if (this.props.selectedTrack.state === AsynchronousItem.STATE_OK) {
      return this.renderNumbers(track);
    } else {
      return '';
    }
  }

  renderLoadingIndicator() {
    return (
      <Grid item xs={12}><CircularProgress /></Grid>
    );
  }

  renderNumbers(track) {
    const numbers = this.props.selectedTrack.value.racers
      .map(racer => (
        <Grid item xs={2} key={racer.id}>
          <Link className='app-link' component={RouterLink} to={'/track/' + track.id + "/" + racer.id}>
            <Paper className='paper rider-chooser-identifier'>{racer.id}</Paper>
          </Link>
        </Grid>
      ));

    return (
      <Fragment>
        {numbers}
      </Fragment>
    );
  }


  openClearResultsWarning = () => {
    this.setClearResultsWarningOpen(true);
    AnalyticsEvent(this.constructor.name, ACTION_CLEAR_RESULTS, "clearing results");
  }

  closeClearResultsWarning = (result) => {
    if (result === true) {
      this.props.reloadPlugin();
    }
    this.setClearResultsWarningOpen(false);
  }
}

export default RiderSelectorComponent;