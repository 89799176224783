import TracksService from "../tracks/track-data/tracks-service";

export const READER_LOAD_READER_PLUGIN = 'READER_LOAD_READER_PLUGIN'


export const READER_RELOAD_PLUGIN = 'READER_RELOAD_PLUGIN'
export const READER_PREPARE_FOR_LOADING = 'READER_PREPARE_FOR_LOADING'

export function loadPlugin(trackId, force = false) {
  return function (dispatch, getState) {
    const state = getState().reader.plugin;

    console.log("Loading plugin " + trackId);
    // console.log(getState())
    // console.log("  Previous plugin: " + (state && state && state.getTrackId()));
    // console.log("  New plugin:      " + trackId)

    if (state && state.getTrackId() === trackId && !force) {
      console.log("  Plugin already loaded");

      return Promise.resolve();

    } else {
      console.log("  Loading new plugin...");
      if (state) {
        state.destroy();
      }

      const plugin = TracksService.getTrack(trackId).pluginFactoryMethod();
      plugin.setTrackId(trackId);

      plugin.beforeLoading();
      plugin.init();

      return dispatch(savePluginToStore(plugin));
    }
  }
}

export function reloadPlugin() {
  return function (dispatch, getState) {
    const state = getState().reader.plugin;

    if (!state) {
      return Promise.resolve();
    }

    const trackId = state.getTrackId();

    console.log("Reloading plugin...");

    return dispatch(loadPlugin(trackId, true));
  }
}

function savePluginToStore(plugin) {
  return {
    type: READER_LOAD_READER_PLUGIN,
    plugin: plugin
  };
}
