import { TRACKS_LOADS_TRACKS_TO_SELECT, TRACKS_LOADS_TRACK_DATA, TRACKS_MARK_AS_LOADING } from "./tracks.actions";
import AsynchronousItem from "../../utils/asynchronous-item";


export const tracksList = (state = [], action) => {
  switch (action.type) {
    case TRACKS_LOADS_TRACKS_TO_SELECT:
      return action.tracks;
    default:
      return state
  }
}

export const selectedTrack = (state = new AsynchronousItem(), action) => {
  switch (action.type) {
    case TRACKS_MARK_AS_LOADING:
      return action.track;
    case TRACKS_LOADS_TRACK_DATA:
      return mergeState(state, action.track);
    default:
      return state
  }
}

function mergeState(previousState, nextState) {
  if (!previousState.value) {
    return nextState;
  }

  const merged = Object.assign({}, previousState);

  if (nextState.state !== AsynchronousItem.STATE_OK) {
    merged.state = nextState.state;
  } else {
    merged.state = nextState.state;
    merged.value = mergeTrack(merged.value, nextState.value)
  }

  return merged;
}

function mergeTrack(mergedTrack, nextTrack) {
  mergedTrack.frameTimestamp = nextTrack.frameTimestamp;
  nextTrack.racers.forEach(racer => {
    mergeRacer(mergedTrack, racer);
  });

  return mergedTrack;
}

function mergeRacer(mergedTrack, racer) {
  const mergedRacer = mergedTrack.racers.find(item => item.id === racer.id);

  if (mergedRacer) {
    if (mergedRacer.lastTime !== racer.lastTime) {
      mergedRacer.times.push(mergedRacer.lastTime);
      mergedRacer.lastTime = racer.lastTime;
    }
  } else {
    mergedTrack.racers.push(racer);
  }
}
