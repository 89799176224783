import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';


import { AnalyticsPageView } from '../utils/react-ga/react-ga-utils';


class WelcomePageComponent extends Component {

  componentDidMount() {
    AnalyticsPageView();
  }

  render() {
    return (
      <div className="welcome-page paper-list-container">
        <Grid container spacing={8}>
          <Grid item xs={12}><Paper className='paper paper-list-header'>Web Lap Timer</Paper></Grid>
          <Grid item xs={12}>
            <Link className='app-link' component={RouterLink} to={'/track'}>
              <Paper className='paper paper-list-header'>Wybierz tor</Paper>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Paper className='long-content paper paper-list'>
              <h2>Czym jest?</h2>
              <p>
                Web Lap Timer jest prostą aplikacją pozwalającą na oglądanie wyników przejazdów na urządzeniu
                mobilnym (np. smartfonie).
                </p>
              <h2>Jak działa?</h2>
              <p>
                Web Lap Timer pobiera wyniki ze strony właściciela toru i prezentuje je na ekranie urządzenia.
                Wyniki te są zwykle dostarczane przy pomocy specjalistycznego sprzętu pomiarowego co gwarantuje ich dokładność.
                Ze względu na opóźnienie w czasie dostarczania wyników przez właściciela toru
                (i/lub właściciela usługi dostarczającej wyniki) oraz opóźnień połączenia internetowego, wyniki mogą być
                prezentowane z pewnym opóźnieniem.
                </p>
              <h2>Po co?</h2>
              <p>
                Aplikacja Web Lap Timer nie powstała w celu zastąpienia komercyjnych urządzeń pomiarowych typu Lap Timer
                montowanych w pojazdach wyścigowych.
                Aplikacja ma na celu umożliwienie wykorzystania podstawowych możliwości takich urządzeń początkującemu
                kierowcy, który nie jest jeszcze zdecydowany na zakup specjalistycznego sprzętu.
              </p>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className='long-content paper paper-list'>
              <h2>Podziękowania</h2>
              <p>
                Icons made by&nbsp;
                <a href="https://www.freepik.com/?__hstc=57440181.01088015e4e9e7e04c96b245a3a2f4e9.1558124055383.1558124055384.1558124055384.1&__hssc=57440181.5.1558124055384&__hsfp=3405558782" title="Freepik">
                  Freepik
                </a>
                &nbsp;from&nbsp;
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
                &nbsp;is licensed by&nbsp;
                <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0">
                  CC 3.0 BY
                </a>
              </p>
            </Paper>
          </Grid>


        </Grid>
      </div>
    );
  }
}

export default WelcomePageComponent;
