import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import CircularProgress from '@material-ui/core/CircularProgress';
import Save from '@material-ui/icons/Save'
import { saveAs } from 'file-saver';


import AsynchronousItem from '../utils/asynchronous-item';
import TimeUtils from '../utils/time-utils';
import { AnalyticsPageView, AnalyticsEvent } from '../utils/react-ga/react-ga-utils';


const ACTION_SAVE_RESULTS = 'ACTION_SAVE_RESULTS';


class TimesListComponent extends Component {

  getTrackId() {
    return this.props.match.params.id;
  }

  getRacerId() {
    return this.props.match.params.racerId;
  }


  componentDidMount() {
    AnalyticsPageView();
    this.props.loadPlugin(this.props.match.params.id);
  }

  render() {
    return (
      <div className="rider-chooser paper-list-container">
        <Grid container spacing={8}>
          <Grid item xs={2}>
            <Link className='app-link' component={RouterLink} to={`/track/${this.getTrackId()}/${this.getRacerId()}/?dangerConfirmed=true`}>
              <Paper className='paper paper-list-header'><KeyboardArrowLeft /></Paper>
            </Link>
          </Grid>
          <Grid item xs={8}><Paper className='paper paper-list-header'>Wyniki</Paper></Grid>
          <Grid item xs={2}>
            <Link className='app-link' onClick={this.saveResults}>
              <Paper className='paper paper-list-header'><Save /></Paper>
            </Link>
          </Grid>
          {this.renderResults()}
        </Grid>
      </div>
    );
  }


  renderResults() {
    if (this.props.selectedTrack.state !== AsynchronousItem.STATE_OK) {
      return this.renderLoader();
    } else {
      return this.renderResultsList();
    }
  }

  renderLoader() {
    return (
      <CircularProgress />
    );
  }

  renderResultsList() {
    const rows = this.getTimesList()
      .map((time, index) => this.renderResultRow(time, index));

    return (
      <Grid item xs={12}>
        <Paper className='paper paper-list'>
          {rows}
        </Paper>
      </Grid>
    );
  }

  renderResultRow(time, index) {
    const timeStrings = TimeUtils.getTimeStrings(time);

    return (
      <p key={index}>
        {`${index}: ${timeStrings[0]}:${timeStrings[1]}.${timeStrings[2]}`}
      </p>
    );
  }


  getTimesList() {
    const track = this.props.selectedTrack.value;
    const racer = track.getRacer(this.getRacerId());

    return [...(racer.times), racer.lastTime];
  }


  saveResults = () => {
    AnalyticsEvent(this.constructor.name, ACTION_SAVE_RESULTS, "saving results");

    var text = this.getTimesList()
      .map((time, index) => this.generateSaveRow(time, index))
      .join("\r\n");

    var filename = this.getFileName();
    var blob = new Blob([text], { type: "text/plain;charset=utf-8" });

    saveAs(blob, filename);
  }

  generateSaveRow(time, index) {
    const timeStrings = TimeUtils.getTimeStrings(time);

    return `${index}: ${timeStrings[0]}:${timeStrings[1]}.${timeStrings[2]}`;
  }

  getFileName() {
    const dateTime = new Date()
    const dateTimeString = dateTime.getFullYear()
      + "-" + (dateTime.getMonth() + 1)
      + "-" + dateTime.getDate()
      + "_" + dateTime.getHours()
      + "-" + dateTime.getMinutes()
      + "-" + dateTime.getSeconds();

    return dateTimeString + ".txt";
  }
}

export default TimesListComponent;