import { loadTrackData, markTrackDataAsLoading } from "../../tracks/track-data/tracks.actions";
import store from "../../store";



class AbstractPlugin {

  getClass = () => {
    return this.constructor.name;
  }

  setTrackId = (trackId) => {
    this.trackId = trackId;
  }

  getTrackId = () => {
    return this.trackId;
  }

  init() {
    throw new Error(`init() method should be overwritten in class: ${this.getClass()}`);
  }

  destroy() {
    throw new Error(`destroy() method should be overwritten in class: ${this.getClass()}`);
  }

  beforeLoading() {
    store.dispatch(markTrackDataAsLoading());
  }

  update(track) {
    store.dispatch(loadTrackData(track));
  }
}

export default AbstractPlugin;