import React, { Component } from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import ReactGA from 'react-ga';


import './App.css';
import TrackSelectorContainer from './app/track-selector/track-selector.container';
import RiderSelectorContainer from './app/rider-selector/rider-selector.container';
import LapTimerContainer from './app/lap-timer/lap-timer.container';
import TimesListContainer from './app/times-list/times-list.container';
import WelcomePageComponent from './app/welcome/welcome-page';
import { AnalyticsPageView } from './app/utils/react-ga/react-ga-utils';
import TrackInfoContainer from './app/track-info/track-info.container';
import NewTrackFaqComponent from './app/track-selector/new-track-faq';


ReactGA.initialize('UA-140420906-1');

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Route exact path="/" component={WelcomePageComponent} />
          <Route exact path="/new-track-faq" component={NewTrackFaqComponent} />
          <Route exact path="/track" component={TrackSelectorContainer} />
          <Route exact path="/track/:id" component={RiderSelectorContainer} />
          <Route exact path="/track-info/:id" component={TrackInfoContainer} />
          <Route exact path="/track/:id/:racerId" component={LapTimerContainer} />
          <Route exact path="/track/:id/:racerId/results" component={TimesListContainer} />
        </Router>
      </div>
    );
  }

  componentDidMount() {
    AnalyticsPageView();
  }
}

export default App;
