import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';


import { AnalyticsPageView } from '../utils/react-ga/react-ga-utils';
import TracksService from '../tracks/track-data/tracks-service';


import './track-info.css';


class TrackInfoComponent extends Component {


  componentDidMount() {
    AnalyticsPageView();
    this.props.loadTracksList();
  }


  render() {
    const track = TracksService.getTrack(this.props.match.params.id);

    return (
      <div className="track-selector paper-list-container">
        <Grid container spacing={8}>
          <Grid item xs={2}>
            <Link className='app-link' component={RouterLink} to={'/track'}>
              <Paper className='paper paper-list-header'><KeyboardArrowLeft /></Paper>
            </Link>
          </Grid>
          <Grid item xs={8}><Paper className='paper paper-list-header'>{track.name}</Paper></Grid>
          <Grid item xs={2}>
            <Paper className='paper paper-list-header'>&nbsp;</Paper>
          </Grid>
        </Grid>

        <Paper className='paper paper-list'>
          <Grid container spacing={8}>
            <Grid item xs={4} className="track-info-row-header">
              Strona:
            </Grid>
            <Grid item xs={8} className="track-info-row-content">
              <a class='app-link' href={track.website}>{track.website}</a>
            </Grid>
          </Grid>
        </Paper>

        <Paper className='paper paper-list'>
          <Grid container spacing={8}>
            <Grid item xs={4} className="track-info-row-header">
              Adres:
            </Grid>
            <Grid item xs={8} className="track-info-row-content">
              {track.address}
            </Grid>
          </Grid>
        </Paper>

        <Paper className='paper paper-list'>
          <Grid container spacing={8}>
            <Grid item xs={4} className="track-info-row-header">
              GPS:
            </Grid>
            <Grid item xs={8} className="track-info-row-content">
              <a class='app-link' href={track.gpsLink}>{track.gps}</a>
            </Grid>
          </Grid>
        </Paper>

        <Paper className='paper paper-list'>
          <Grid container spacing={8}>
            <Grid item xs={4} className="track-info-row-header">
              Mapa:
            </Grid>
            <Grid item xs={8} className="track-info-row-header">
              &nbsp;
            </Grid>
            <Grid item xs={12} className="track-info-row-content">
              <iframe src={track.mapLink} width="100%" height="450" frameborder="0" allowfullscreen title="lokalizacja toru"></iframe>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default TrackInfoComponent;