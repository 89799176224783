import { connect } from 'react-redux'


import RiderSelectorComponent from './rider-selector.component';
import { loadPlugin, reloadPlugin } from '../reader/reader.actions';


const mapStateToProps = state => {
  return ({
    selectedTrack: state.tracks.selectedTrack
  })
};

const mapDispatchToProps = dispatch => ({
  loadPlugin: (trackId) => dispatch(loadPlugin(trackId)),
  reloadPlugin: () => dispatch(reloadPlugin()),
});

const RiderSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RiderSelectorComponent);

export default RiderSelectorContainer;