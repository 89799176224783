import ReactGA from 'react-ga';


export const AnalyticsPageView = () => {
  const location = window.location.pathname + window.location.search + window.location.hash;
  console.debug(`ReactGA.pageview(${location})`);
  ReactGA.pageview(location);
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const AnalyticsEvent = (category, action, label) => {
  console.debug(`AnalyticsEvent('${category}', '${action}', '${label}')`);
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};