import DataNotAvailableError from "./errors/data-not-available-error";


export function returnIfAvailable(data, fieldName) {
  if (data) {
    return data;
  } else {
    throw new DataNotAvailableError(fieldName);
  }
}
