import AbstractPlugin from "./abstract-plugin";
import { returnIfAvailable } from "../../utils/validators";
import DataNotAvailableError from "../../utils/errors/data-not-available-error";
import TrackData from "../../tracks/track-data/track-data";
import RacerData from "../../tracks/track-data/racer-data";

// const URL = "ws://localhost:3005"
const URL = "wss://api.kartingmanager.com/wss2/NNN"

class KartingManagerComPlugin extends AbstractPlugin {
  constructor(trackName) {
    super();
    this.trackName = trackName;
  }

  init() {
    this.socket = new WebSocket(URL);
    this.socket.onopen = this.onopen;
    this.socket.onmessage = this.onmessage;
  }

  destroy() {
    this.socket.close();
  }

  onopen = () => {
    this.beforeLoading();
    this.socket.send(JSON.stringify({ "instance": this.trackName }));
  }

  onmessage = (event) => {
    try {
      const data = JSON.parse(returnIfAvailable(event.data, "event.data"));
      const raceResults = JSON.parse(returnIfAvailable(data.raceResults, "data.raceResults"));

      const racers = raceResults.drivers
        .map(driver =>
          new RacerData(driver.kartNr, Number(driver.last))
        );

      const track = new TrackData(new Date(), this.getTrackId(), this.trackName, racers);

      this.update(track);
    } catch (e) {
      if (e instanceof DataNotAvailableError) {
        console.log(`Empty message (${e.message})!`);
      } else {
        throw e;
      }
    }
  }
}

export default KartingManagerComPlugin;