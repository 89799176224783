import { TRACKS } from "../../tracks-configuration";


class TracksService {
  static getTracks() {
    return TRACKS;
  }

  static getTrack(id) {
    return TRACKS.find(track => track.id === id);
  }
}



export default TracksService;