import { connect } from 'react-redux'


import { loadPlugin } from '../reader/reader.actions';
import TimesListComponent from './times-list.component';


const mapStateToProps = state => {
  return ({
    selectedTrack: state.tracks.selectedTrack
  })
};

const mapDispatchToProps = dispatch => ({
  loadPlugin: (trackId) => dispatch(loadPlugin(trackId))
});

const TimesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesListComponent);

export default TimesListContainer;