import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import ListIcon from '@material-ui/icons/List'
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string'


import AsynchronousItem from '../utils/asynchronous-item';
import LapTimerCanvas from './lap-timer-canvas';
import LapTimerWarning from './lap-timer-warning';


import './lap-timer.css';
import { AnalyticsPageView } from '../utils/react-ga/react-ga-utils';


class LapTimerComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dangerConfirmed: false,
    };
  }

  setDangerConfirmed(dangerConfirmed) {
    this.setState({
      dangerConfirmed: dangerConfirmed
    });
  }

  isDangerConfirmed() {
    return this.state.dangerConfirmed;
  }

  getTrackId() {
    return this.props.match.params.id;
  }

  getRacerId() {
    return this.props.match.params.racerId;
  }


  componentDidMount() {
    AnalyticsPageView();
    this.props.loadPlugin(this.props.match.params.id);

    this.setState((state, props) => {

      const values = queryString.parse(props.location.search);
      console.log(values.dangerConfirmed);
      const dangerConfirmed = (values.dangerConfirmed && values.dangerConfirmed === "true") ? true : false;

      return ({
        dangerConfirmed: dangerConfirmed
      });
    });
  }

  render() {
    if (this.props.selectedTrack.state !== AsynchronousItem.STATE_OK) {
      return this.renderLoader();
    } else if (!this.isDangerConfirmed()) {
      return (
        <Fragment>
          <LapTimerWarning trackId={this.getTrackId()} onAccept={() => this.setDangerConfirmed(true)} />
        </Fragment>
      );
    } else {
      return this.renderTimer();
    }
  }


  renderLoader() {
    return (
      <CircularProgress />
    );
  }

  renderError(message) {
    return (
      <div className="lap-timer paper-list-container">
        <Grid container spacing={8}>
          <Grid item xs={2}>
            <Link className='app-link' component={RouterLink} to={`/track/${this.getTrackId()}`}>
              <Paper className='paper paper-list-header'><KeyboardArrowLeft /></Paper>
            </Link>
          </Grid>
          <Grid item xs={10}><Paper className='paper paper-list-header'>Error</Paper></Grid>
          <Grid item xs={12}><Paper className='paper paper-list-error'>{message}</Paper></Grid>
        </Grid>
      </div>
    );
  }

  renderTimer() {
    const track = this.props.selectedTrack.value;
    const racer = track.getRacer(this.getRacerId());

    if (!racer) {
      return this.renderError("Nieprawidłowy numer uczestnika");
    }

    return (
      <Fragment>
        {this.renderBackButton()}
        {this.renderTimesListButton()}
        <LapTimerCanvas racer={racer}></LapTimerCanvas>
      </Fragment>
    );
  }

  renderBackButton() {
    return (
      <Link className='app-link' component={RouterLink} to={`/track/${this.getTrackId()}`}>
        <Paper className="lap-timer-back-button-paper">
          <KeyboardArrowLeft />
        </Paper>
      </Link>
    );
  }

  renderTimesListButton() {
    return (
      <Link className='app-link' component={RouterLink} to={`/track/${this.getTrackId()}/${this.getRacerId()}/results`}>
        <Paper className="lap-timer-times-list-button-paper">
          <ListIcon />
        </Paper>
      </Link>
    );
  }
}

export default LapTimerComponent;