import React, { Component, Fragment } from 'react';


import TimeUtils from '../utils/time-utils';

const NoSleep = require('nosleep.js');


const FONT_SIZE_RECALCULATION_MARGIN = 0.05;

const MARGIN_TOP = 0.1;
const MAX_FONT_RATIO_WIDTH = 0.9;
const MAX_FONT_RATIO_HEIGHT = 0.5;
const COLOR_INDICATOR_HEIGHT = 0.3;
const FONT_TYPE = "monospace";

const COLOR_BACKGROUND = "#FFFFFF";
const COLOR_TEXT = "#000000";
const COLOR_INDICATOR_RED = "#FF0000";
const COLOR_INDICATOR_GREEN = "#008e21";

const TEST_TIME_STRING = "0:00.000";

class LapTimerCanvas extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fontSize: 0,
    };
  }

  setFontSize(fontSize) {
    this.setState({
      fontSize: fontSize
    });
  }

  getFontSize() {
    return this.state.fontSize;
  }


  componentDidMount() {
    this.draw();
    this.noSleep = new NoSleep();
    this.noSleep.enable();
  }

  componentDidUpdate() {
    this.draw();
  }

  componentWillUnmount() {
    this.noSleep.disable();
  }

  render() {
    return (
      <Fragment>
        <canvas ref="canvas" id="lap-timer-canvas"></canvas>
      </Fragment>
    );
  }


  draw() {
    const canvas = this.refs.canvas;

    this.resizeCanvas(canvas);

    this.drawBackground(canvas);

    this.drawTime(canvas, this.props.racer);

    this.drawColorMarker(canvas, this.props.racer);
  }

  drawBackground(canvas) {
    const context = canvas.getContext("2d")

    context.fillStyle = COLOR_BACKGROUND;
    context.fillRect(0, 0, canvas.width, canvas.height);
  }

  drawTime(canvas, racer) {
    const context = canvas.getContext("2d");

    const time = TimeUtils.getTimeStrings(racer.lastTime);
    console.log(time);
    const timeString = time[0] + ":" + time[1] + "." + time[2];

    context.font = this.getCalculatedFontSize(canvas) + "px " + FONT_TYPE;
    context.textAlign = "center"
    context.textBaseline = "top"
    context.fillStyle = COLOR_TEXT;
    context.fillText(timeString, canvas.width / 2, canvas.height * MARGIN_TOP);

  }

  drawColorMarker(canvas, racer) {
    if (racer.times.length < 1) {
      return;
    }

    const context = canvas.getContext("2d");

    const height = canvas.height * COLOR_INDICATOR_HEIGHT;
    const timesLength = racer.times.length;
    const timesLast = racer.lastTime;
    const timesBeforeLast = racer.times[timesLength - 1];

    context.fillStyle = (timesLast < timesBeforeLast) ? COLOR_INDICATOR_GREEN : COLOR_INDICATOR_RED;
    context.fillRect(0, canvas.height - height, canvas.width, height);
  }


  resizeCanvas(canvas) {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }


  getCalculatedFontSize(canvas) {
    const lastFontSize = this.getFontSize();

    let fontSize;
    if (this.isFontWidthOkAndHeightBelow(canvas) || this.isFontHeightOkAndWidthBelow(canvas)) {
      fontSize = lastFontSize;
    } else {
      fontSize = this.recalculateFontSize(canvas);

      if (lastFontSize !== fontSize) {
        this.setFontSize(fontSize);
      }
    }

    return fontSize;
  }

  isFontWidthOkAndHeightBelow(canvas) {
    const context = canvas.getContext("2d");
    const textWidth = this.getTimeTextWidth(context);
    const fontSize = this.getFontSize();

    const minTextWidth = canvas.width * (MAX_FONT_RATIO_WIDTH - FONT_SIZE_RECALCULATION_MARGIN);
    const maxTextWidth = canvas.width * (MAX_FONT_RATIO_WIDTH + FONT_SIZE_RECALCULATION_MARGIN);
    const maxTextHeight = canvas.height * MAX_FONT_RATIO_HEIGHT;

    return minTextWidth <= textWidth && textWidth <= maxTextWidth && fontSize < maxTextHeight;
  }

  isFontHeightOkAndWidthBelow(canvas) {
    const context = canvas.getContext("2d");
    const textWidth = this.getTimeTextWidth(context);
    const fontSize = this.getFontSize();

    const minTextHeight = canvas.height * (MAX_FONT_RATIO_HEIGHT - FONT_SIZE_RECALCULATION_MARGIN);
    const maxTextHeight = canvas.height * (MAX_FONT_RATIO_HEIGHT + FONT_SIZE_RECALCULATION_MARGIN);
    const maxTextWidth = canvas.width * MAX_FONT_RATIO_WIDTH;

    return minTextHeight <= fontSize && fontSize <= maxTextHeight && textWidth < maxTextWidth;
  }

  getTimeTextWidth(context, fontSize = this.getFontSize()) {
    context.font = fontSize + "px " + FONT_TYPE;
    return context.measureText(TEST_TIME_STRING).width;
  }

  recalculateFontSize(canvas) {
    const context = canvas.getContext("2d");

    let fontSize = 0;
    let width;
    let widthRatio;
    let heightRatio;
    do {
      fontSize += 1;

      width = this.getTimeTextWidth(context, fontSize);

      widthRatio = width / canvas.width;
      heightRatio = fontSize / canvas.height;

    } while (widthRatio <= MAX_FONT_RATIO_WIDTH && heightRatio <= MAX_FONT_RATIO_HEIGHT);

    return fontSize;
  }

}

export default LapTimerCanvas;