import { connect } from 'react-redux'


import TrackInfoComponent from './track-info.component';
import { loadTracksList } from '../tracks/track-data/tracks.actions';


const mapStateToProps = state => {
  return ({
    tracksList: state.tracks.tracksList,
    selectedTrack: state.tracks.selectedTrack,
  })
};

const mapDispatchToProps = dispatch => ({
  loadTracksList: () => dispatch(loadTracksList())
});

const TrackInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackInfoComponent);

export default TrackInfoContainer;