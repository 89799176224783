import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

class LapTimerWarning extends Component {
  render() {
    const trackId = this.props.trackId;

    return (
      <div className="rider-chooser paper-list-container">
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Link className='app-link' component={RouterLink} to={`/track/${trackId}`}>
              <Paper className='paper paper-list-header'>Nie akceptuję</Paper>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link className='app-link' onClick={this.props.onAccept}>
              <Paper className='paper paper-list-header'>Akceptuję</Paper>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Paper className='paper paper-list-header'>Ostrzeżenie</Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className='paper paper-list'>
              <p>Korzystanie z aplikacji na torze podczas jazdy może wiązać się z utratą koncentracji i poważnym wypadkiem.</p>
              <p>Korzystasz z aplikacji na własne ryzyko.</p>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default LapTimerWarning;