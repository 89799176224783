import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';


import './track-selector.css'
import WarningDialog from '../utils/warning-dialog/warning-dialog';


class TrackSelectorRow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clearResultsWarningOpen: false
    };
  }

  setClearResultsWarningOpen = (value) => {
    this.setState({
      clearResultsWarningOpen: value
    });
  }


  render() {
    const track = this.props.track;

    return (
      <Fragment>
        <Grid item xs={12} className='track-selector-row'>
          <Paper className='paper'>
            <Grid container>
              <Grid item xs={4} className='track-selector-row-item'>
                <Link className='app-link' onClick={() => this.openClearResultsWarning()}>
                  {track.city}
                </Link>
              </Grid>
              <Grid item xs={6} className='track-selector-row-item'>
                <Link className='app-link' onClick={() => this.openClearResultsWarning()}>
                  {track.name}
                </Link>
              </Grid>
              <Grid item xs={2} className='track-selector-row-item-info'>
                <Link className='app-link' component={RouterLink} to={`/track-info/${track.id}`}>
                  <InfoIcon />
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <WarningDialog
          openFlag={this.state.clearResultsWarningOpen}
          onClose={(result) => this.closeClearResultsWarning(result)}
          title="Czy zmienić tor?"
          body="Zmiana toru spowoduje usunięcie wyników pobranych dla obecnie obserwowanego toru. Usunięcie jest nieodwracalne. Czy na pewno chcesz to zrobić?"
        />
      </Fragment>
    );
  }


  openClearResultsWarning = () => {
    const track = this.props.track;
    const selectedTrack = this.props.selectedTrack;

    if (!selectedTrack || track.id === selectedTrack.id) {
      this.redirectToTrack();
    } else {
      this.setClearResultsWarningOpen(true);
    }
  }

  closeClearResultsWarning = (result) => {
    if (result === true) {
      this.redirectToTrack();
    }
    this.setClearResultsWarningOpen(false);
  }

  redirectToTrack = () => {
    const track = this.props.track;

    this.props.history.push('/track/' + track.id);
  }
}

export default withRouter(TrackSelectorRow);