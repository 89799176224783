class TimeUtils {
  static getTimeStrings(inputSeconds) {
    const milliseconds = Number.parseInt((inputSeconds * 1000) % 1000) + "";
    let seconds = Number.parseInt(inputSeconds) % 60 + "";
    let minutes = Number.parseInt(inputSeconds / 60) + "";

    if (seconds.length === 1) {
      seconds = "0" + seconds;
    }

    return [minutes, seconds, milliseconds];
  }
}

export default TimeUtils;