import { combineReducers } from 'redux'


import { READER_LOAD_READER_PLUGIN } from './reader.actions';


export const plugin = (state = null, action) => {
  switch (action.type) {
    case READER_LOAD_READER_PLUGIN:
      return action.plugin;
    default:
      return state
  }
}

export default combineReducers({
  plugin
})