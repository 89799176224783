class AsynchronousItem {
  static get STATE_UNKNOWN() { return "STATE_UNKNOWN"; }
  static get STATE_LOADING() { return "STATE_LOADING"; }
  static get STATE_OK() { return "STATE_OK"; }
  static get STATE_ERROR() { return "STATE_ERROR"; }

  constructor(state = AsynchronousItem.STATE_UNKNOWN, value = null, error = null) {
    this.state = state;
    this.value = value;
    this.error = error;
  }
}

export default AsynchronousItem;