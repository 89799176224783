import { connect } from 'react-redux'


import LapTimerComponent from './lap-timer.component';
import { loadPlugin } from '../reader/reader.actions';


const mapStateToProps = state => {
  return ({
    selectedTrack: state.tracks.selectedTrack
  })
};

const mapDispatchToProps = dispatch => ({
  loadPlugin: (trackId) => dispatch(loadPlugin(trackId))
});

const LapTimerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LapTimerComponent);

export default LapTimerContainer;