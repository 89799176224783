import AbstractPlugin from "./abstract-plugin";
import TrackData from "../../tracks/track-data/track-data";
import RacerData from "../../tracks/track-data/racer-data";
import LocalMath from "../../utils/local-math";

class TestTrackPlugin extends AbstractPlugin {

  init() {
    this.dataInterval = setInterval(this.createMessage.bind(this), 1000);
  }

  destroy() {
    clearInterval(this.dataInterval);
  }

  createMessage = () => {
    const racers = [1, 2, 3]
      .map(driverId =>
        new RacerData(driverId, Number("10." + LocalMath.getRandomInt(100, 999)))
      );

    const track = new TrackData(new Date(), this.getTrackId(), 'Tor testowy', racers);

    this.update(track);
  }
}

export default TestTrackPlugin;