import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import AtIcon from '@material-ui/icons/AlternateEmailOutlined';


import { AnalyticsPageView } from '../utils/react-ga/react-ga-utils';

class NewTrackFaqComponent extends Component {


  componentDidMount() {
    AnalyticsPageView();
  }


  render() {
    return (
      <div className="track-selector paper-list-container">
        <Grid container spacing={8}>
          <Grid item xs={2}>
            <Link className='app-link' component={RouterLink} to={'/track'}>
              <Paper className='paper paper-list-header'><KeyboardArrowLeft /></Paper>
            </Link>
          </Grid>
          <Grid item xs={8}><Paper className='paper paper-list-header'>Nowy tor - czego potrzebuję?</Paper></Grid>
          <Grid item xs={2}>
            <Paper className='paper paper-list-header'>&nbsp;</Paper>
          </Grid>
        </Grid>

        <Paper className='paper paper-list long-content'>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <h2>Jak to działa?</h2>
              <p>
                Aplikacja została stworzona w taki sposób, żeby było możliwe dodawanie nowych torów.
                Ponieważ strona sama w sobie nie wykonuje pomiarów, a jedynie je wyświetla,
                niezbędny jest istniejący system pomiarowy umieszczony fizycznie na torze.
                Oprócz systemu pomiarowego, wyniki muszą być dostępne w internecie.
                Muszą też być "parsowalne" to znaczy musi istnieć stosunkowo nietrudny sposób rozpoznania treści strony
                i oddzielenia od siebie wyników poszczególnych uczestników.
              </p>
              <p>
                Wszystkie treści na stronie tworzone są bezpłatnie.
                Wszystkie modyfikacje strony wykonuję "po godzinach" w wolnym czasie.
                Miejcie więc odrobinę wyrozumiałości i cierpliwości ;)
              </p>
              <h2>Podsumowując - potrzebuję:</h2>
              <p>
                <ul>
                  <li>Zewnętrznego systemu pomiarowego</li>
                  <li>Strony internetowej z wynikami (parsowalnej)</li>
                  <li>Trochę wolnego czasu ;)</li>
                  <li>Informacji od was na mailu lukasz.kotynski.dev<AtIcon className='magicIcon' />gmail.com</li>
                </ul>
              </p>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default NewTrackFaqComponent;